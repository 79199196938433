<template>
  <div>
    <!-- 标题 -->
    <!-- <div>
      <van-nav-bar title="资讯中心" />
    </div> -->
    <!-- tab栏 -->
    <div class="information">
      <van-tabs v-model="riKind"
        @click="onClick">
        <van-tab title="全部"
          name="0">
          <!-- 全部 -->
          <van-list v-model="loading"
            :finished="finished"
            offset="50">
            <div class="center"
              v-for="item in list"
              :key="item.index">
              <div @click="gotoUrl(item)">
                <div class="centerTitle van-ellipsis">{{ item.Title }}</div>
                <div class="Content">
                  <div style="display: inline-block"
                    class="ContentA">
                    <img :src="item.ThematicUrl"
                      alt="" />
                  </div>
                  <div style="display: inline-block"
                    class="ContentB">
                    <span class="van-multi-ellipsis--l3">{{
                      item.Summary
                    }}</span>
                    <br />
                    <span class="centertime">{{ item.IssTime }}</span>
                  </div>
                </div>
              </div>
            </div>
          </van-list>
        </van-tab>
        <van-tab title="通知公告"
          name="1">
          <!-- 通知公告 -->
          <div class="center"
            v-for="item in list"
            :key="item.index">
            <div @click="gotoUrl(item)">
              <div class="centerTitle van-ellipsis">{{ item.Title }}</div>
              <div class="Content">
                <div style="display: inline-block"
                  class="ContentA">
                  <img :src="item.ThematicUrl"
                    alt="" />
                </div>
                <div style="display: inline-block"
                  class="ContentB">
                  <span class="van-multi-ellipsis--l3">{{ item.Summary }}</span>
                  <br />
                  <span class="centertime">{{ item.IssTime }}</span>
                </div>
              </div>
            </div>
          </div>
        </van-tab>
        <van-tab title="政务公开"
          name="2">
          <!-- 政务公开 -->
          <div class="center"
            v-for="item in list"
            :key="item.index">
            <div @click="gotoUrl(item)">
              <div class="centerTitle van-ellipsis">{{ item.Title }}</div>
              <div class="Content">
                <div style="display: inline-block"
                  class="ContentA">
                  <img :src="item.ThematicUrl"
                    alt="" />
                </div>
                <div style="display: inline-block"
                  class="ContentB">
                  <span class="van-multi-ellipsis--l3">{{ item.Summary }}</span>
                  <br />
                  <span class="centertime">{{ item.IssTime }}</span>
                </div>
              </div>
            </div>
          </div>
        </van-tab>
        <van-tab title="民生保障"
          name="3">
          <!-- 民生保障 -->
          <div class="center"
            v-for="item in list"
            :key="item.index">
            <div @click="gotoUrl(item)">
              <div class="centerTitle van-ellipsis">{{ item.Title }}</div>
              <div class="Content">
                <div style="display: inline-block"
                  class="ContentA">
                  <img :src="item.ThematicUrl"
                    alt="" />
                </div>
                <div style="display: inline-block"
                  class="ContentB">
                  <span class="van-multi-ellipsis--l3">{{ item.Summary }}</span>
                  <br />
                  <span class="centertime">{{ item.IssTime }}</span>
                </div>
              </div>
            </div>
          </div>
        </van-tab>
        <van-tab title="惠民政策"
          name="4">
          <!-- 惠民政策 -->
          <div class="center"
            v-for="item in list"
            :key="item.index">
            <div @click="gotoUrl(item)">
              <div class="centerTitle van-ellipsis">{{ item.Title }}</div>
              <div class="Content">
                <div style="display: inline-block"
                  class="ContentA">
                  <img :src="item.ThematicUrl"
                    alt="" />
                </div>
                <div style="display: inline-block"
                  class="ContentB">
                  <span class="van-multi-ellipsis--l3">{{ item.Summary }}</span>
                  <br />
                  <span class="centertime">{{ item.IssTime }}</span>
                </div>
              </div>
            </div>
          </div>
        </van-tab>
        <van-tab title="文化教育"
          name="5">
          <!-- 文化教育 -->
          <div class="center"
            v-for="item in list"
            :key="item.index">
            <div @click="gotoUrl(item)">
              <div class="centerTitle van-ellipsis">{{ item.Title }}</div>
              <div class="Content">
                <div style="display: inline-block"
                  class="ContentA">
                  <img :src="item.ThematicUrl"
                    alt="" />
                </div>
                <div style="display: inline-block"
                  class="ContentB">
                  <span class="van-multi-ellipsis--l3">{{ item.Summary }}</span>
                  <br />
                  <span class="centertime">{{ item.IssTime }}</span>
                </div>
              </div>
            </div>
          </div>
        </van-tab>
        <!-- <van-tab title="社区风采">
          <div class="center" v-for="item in list" :key="item.index">
            <div @click="gotoUrl(item)">
              <div class="centerTitle van-ellipsis">{{ item.Title }}</div>
              <div class="Content">
                <div style="display: inline-block" class="ContentA">
                  <img :src="item.ThematicUrl" alt="" />
                </div>
                <div style="display: inline-block" class="ContentB">
                  <span class="van-multi-ellipsis--l3">{{ item.Summary }}</span>
                  <br />
                  <span class="centertime">{{ item.IssTime }}</span>
                </div>
              </div>
            </div>
          </div>
        </van-tab> -->
        <van-tab title="志愿者风采"
          name="7">
          <!-- 志愿者风采 -->
          <div class="center"
            v-for="item in list"
            :key="item.index">
            <div @click="gotoUrl(item)">
              <div class="centerTitle van-ellipsis">{{ item.Title }}</div>
              <div class="Content">
                <div style="display: inline-block"
                  class="ContentA">
                  <img :src="item.ThematicUrl"
                    alt="" />
                </div>
                <div style="display: inline-block"
                  class="ContentB">
                  <span class="van-multi-ellipsis--l3">{{ item.Summary }}</span>
                  <br />
                  <span class="centertime">{{ item.IssTime }}</span>
                </div>
              </div>
            </div>
          </div>
        </van-tab>
      </van-tabs>
      <!-- 没有更多数据了 -->
      <div v-if="hidden"
        class="hidden">───── 没有更多数据了 ─────</div>
    </div>
  </div>
</template>
<script>
import { WeGetRealInfoPage, GetRealSortList } from "@/api/index";
import wx from "weixin-js-sdk";
export default {
  data () {
    return {
      riKind: 0, //tab栏
      list: {}, //资讯列表
      listfrom: {
        page: 0,
        limit: 10,
      }, //查询传参
      hidden: true, //没有更多数据了
      loading: false, //加载
      finished: false, //数据完全加载结束为 true
      total: undefined, //数据条数
    };
  },
  methods: {
    gotoUrl (news) {
      if (news.WechatUrl && news.WechatUrl.length) {
        if (window.__wxjs_environment === "miniprogram") {
          window.wx.miniProgram.navigateTo({
            url: "../public/goto?path=" + encodeURIComponent(news.WechatUrl),
          });
        } else {
          window.open(news.WechatUrl);
        }
      } else {
        this.$router.push('/article/detail/' + news.RIId);
      }
    },
    getRealSortList () {
      GetRealSortList()
        .then((res) => {
          console.log(res);
        })
    },
    // 获取分页列表
    getList () {
      this.listfrom.riKind = this.riKind;
      WeGetRealInfoPage(this.listfrom)
        .then((res) => {
          if (res.data.code == 0) {
            if (res.data.count == 0) {
              // this.hidden = true;
              this.finished = true;
              this.list = {};
            } else {
              this.list = res.data.data;
              this.total = res.data.count;
              this.finished = true;
              // this.hidden = false;
              for (var i = 0; i < this.list.length; i++) {
                var item = this.list[i].IssTime;
                item = this.dataFormat(item);
                this.list[i].IssTime = item;
                if (this.list[i].ThematicUrl == "") {
                  this.list[i].ThematicUrl =
                    "https://cuiyunapi.dexian.ren//upload/images/2021/7/873b94ff-81d.png";
                }
              }
            }
          }
        })
        .catch(() => { });
    },
    // 下拉刷新
    onLoad () {
      console.log(123);
      this.listfrom.page = this.listfrom.page + 1;
      this.getList();
    },
    // tan点击切换事件
    onClick (e) {
      this.riKind = e;
      this.listfrom.page = 1;
      this.getList();
    },
    // 时间格式化
    dataFormat (value) {
      var year = value.substr(0, 4);
      var month = value.substr(5, 2);
      var day = value.substr(8, 2);
      var hour = value.substr(11, 2);
      var min = value.substr(14, 2);
      var second = value.substr(17, 2);
      return (
        year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + second
      );
    },
  },
  mounted () {
    // this.getRealSortList()
    this.$nextTick(function () {
      this.getList();
    });
  },
};
</script>
<style>
.hidden {
  text-align: center;
  font-size: 12px;
  color: #ccc;
  margin: 10px 0px 25px 0px;
}
</style>